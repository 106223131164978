import firebase from 'firebase';

var config = {
    apiKey: 'AIzaSyCdF83zlmUT-Nvvy16lFIdGtd0hanqZwmo',
    authDomain: 'project-5-20936.firebaseapp.com',
    databaseURL: 'https://project-5-20936.firebaseio.com',
    projectId: 'project-5-20936',
    storageBucket: 'project-5-20936.appspot.com',
    messagingSenderId: '211197554426',
};
firebase.initializeApp(config);

export default firebase;
